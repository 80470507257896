import {MouseEventHandler, useState, useEffect, useCallback} from 'react';

import AbortIcon from '@/Components/Icons/AbortIcon';

const FlashComponent = (
    {
        level,
        message,
        closeFn,
    }: {
        level: 'success' | 'error',
        message: string,
        closeFn: MouseEventHandler
    }
) => {
    const bgColor = level === 'success' ? 'bg-green-100' : 'bg-red-100';
    const [fadeOut, setFadeOut] = useState(false);

    const handleFadeOutAndClose = useCallback((force = false) => {
        if (level === 'success' || force) {
            setFadeOut(true);
            setTimeout(closeFn, 500);
        }
    }, [closeFn, level]);

    useEffect(() => {
        const autoFadeOutTimer = setTimeout(handleFadeOutAndClose, 20000);
        return () => clearTimeout(autoFadeOutTimer);
    }, [handleFadeOutAndClose]);

    return (
        <div
            className={`${fadeOut ? 'opacity-0 transition-opacity duration-500' : ''} ${bgColor} border px-4 py-3 rounded-sm relative mb-5`}
            data-testid={`${level}_flash_message`}
            role="alert"
        >
            <span className={`text-inherit block sm:inline ${fadeOut ? 'hidden' : ''}`}>
                {message}
            </span>
            <button
                className={`absolute top-0 bottom-0 right-0 px-4 py-3 ${fadeOut ? 'hidden' : ''} cursor-pointer`}
                onClick={() => handleFadeOutAndClose(true)}
                type="button"
                tabIndex={0}
                data-testid="flash_close_btn"
                aria-label="Schliessen"
            >
                <AbortIcon/>
            </button>
        </div>
    );
};

export default FlashComponent;
