import {Component, ErrorInfo, PropsWithChildren} from 'react';
import axios from 'axios';

import FlashMessageContainer from '@/Components/Flash/FlashMessageContainer';
import getRoute from '@/Helpers/getRoute';
import {router} from '@inertiajs/react';

class ErrorHandlerContainer extends Component<PropsWithChildren<object>, {
    error: null | { error: Error, info: ErrorInfo }
}> {

    constructor(props: object) {
        super(props);

        this.state = {error: null};
    }

    componentDidMount() {
        // @ts-ignore
        router.on('navigate', () => window.matomoTrackPageView?.());
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        this.setState({
            error: {
                error,
                info,
            },
        });

        axios.post(getRoute('error.log', {
            message: error.message || 'Error',
            trace: info.componentStack,
        }));
    }

    render() {
        return this.state.error === null
            ? this.props.children
            : (
                <FlashMessageContainer
                    sessionFlashState={{error: 'Es ist ein unerwarteter Fehler aufgetreten. Bitte kontaktieren Sie bei wiederholtem Auftreten einen Administrator.'}}
                />
            );
    }

}

export default ErrorHandlerContainer;
