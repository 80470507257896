import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {createInertiaApp} from '@inertiajs/react';
import ErrorHandler from '@/Layouts/ErrorHandler';
import {createRoot} from 'react-dom/client';
import '../css/app.css';
import '../css/nova.css';
import '@/bootstrap';
// @ts-ignore
import.meta.glob([
    '../css/fonts/**',
]);

/**
 * "MUI: The value provided to Autocomplete is invalid." Warning ausschalten wegen irrelevanten Warnungen beim asynchronen Laden der Auswahlmöglichkeiten
 * https://github.com/mui/material-ui/issues/29727#issuecomment-1091963830
 */
const consoleWarn = console.warn;

console.warn = (msg, ...args) => {
    if (!msg.includes('MUI: The value provided to Autocomplete is invalid.')) {
        consoleWarn(msg, ...args);
    }
};

const resolve = async (name: string) => {
    const matched = /@(.*)::/.exec(name);

    if (matched === null) {
        // @ts-ignore
        return resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx'));
    }

    const module = matched[1];
    const pageName = name.replace(matched[0], '');

    // @ts-ignore
    return resolvePageComponent(`./Modules/${module}/Pages/${pageName}.tsx`, import.meta.glob('./Modules/*/Pages/**/*.tsx'));
};

createInertiaApp({
    resolve,
    setup(
        {
            el,
            App,
            props,
        }
    ) {
        createRoot(el).render(
            <ErrorHandler><App {...props}/></ErrorHandler>
        );
    },
    progress: {
        delay: 0,
    },
});
