const Icon = (
    {
        icon,
        iconStyle = 'light',
        className = '',
        ...props
    }: {
        icon: string,
        iconStyle?: 'solid' | 'regular' | 'light' | 'thin',
        className?: string
    }
) => <i className={`fa-fw fa-${iconStyle} fa-${icon} ${className}`} {...props}/>;

export default Icon;
