import {useEffect, useState} from 'react';

import FlashComponent from '@/Components/Flash/FlashComponent';
import FlashState from '@/Types/State/FlashState';

const FlashMessageContainer = (
    {
        sessionFlashState,
    }: {
        sessionFlashState: FlashState,
    }
) => {
    const [internalFlashState, setInternalFlashState] = useState<FlashState>(sessionFlashState);
    useEffect(() => setInternalFlashState(sessionFlashState), [sessionFlashState]);

    const closeSuccessMessage = () => setInternalFlashState(prevState => ({
        ...prevState,
        success: null,
    }));

    const closeErrorMessage = () => setInternalFlashState(prevState => ({
        ...prevState,
        error: null,
    }));

    return (
        <>
            {internalFlashState.success && (
                <FlashComponent level="success" message={internalFlashState.success} closeFn={closeSuccessMessage}/>
            )}

            {internalFlashState.error && (
                <FlashComponent level="error" message={internalFlashState.error} closeFn={closeErrorMessage}/>
            )}
        </>
    );
};

export default FlashMessageContainer;
